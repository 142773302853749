exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-direction-page-js": () => import("./../../../src/templates/direction-page.js" /* webpackChunkName: "component---src-templates-direction-page-js" */),
  "component---src-templates-form-page-js": () => import("./../../../src/templates/form-page.js" /* webpackChunkName: "component---src-templates-form-page-js" */),
  "component---src-templates-front-office-page-js": () => import("./../../../src/templates/front-office-page.js" /* webpackChunkName: "component---src-templates-front-office-page-js" */),
  "component---src-templates-game-post-js": () => import("./../../../src/templates/game-post.js" /* webpackChunkName: "component---src-templates-game-post-js" */),
  "component---src-templates-hall-of-fame-page-js": () => import("./../../../src/templates/hall-of-fame-page.js" /* webpackChunkName: "component---src-templates-hall-of-fame-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-inner-page-1-js": () => import("./../../../src/templates/inner-page-1.js" /* webpackChunkName: "component---src-templates-inner-page-1-js" */),
  "component---src-templates-list-page-js": () => import("./../../../src/templates/list-page.js" /* webpackChunkName: "component---src-templates-list-page-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-roster-page-js": () => import("./../../../src/templates/roster-page.js" /* webpackChunkName: "component---src-templates-roster-page-js" */),
  "component---src-templates-schedule-page-js": () => import("./../../../src/templates/schedule-page.js" /* webpackChunkName: "component---src-templates-schedule-page-js" */),
  "component---src-templates-search-page-js": () => import("./../../../src/templates/search-page.js" /* webpackChunkName: "component---src-templates-search-page-js" */),
  "component---src-templates-sponsor-page-js": () => import("./../../../src/templates/sponsor-page.js" /* webpackChunkName: "component---src-templates-sponsor-page-js" */)
}

